import * as React from "react";
import { UitkSpacing } from "@egds/react-core/spacing";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";
import { Experiment, ExperimentControl, ExperimentVariant } from "@shared-ui/experiment-context";

import { AnsweringTravellerQuestionsContentSearch } from "@shared-ui/retail-product-answering-traveller-questions";
import { ProductBexApiWrapper } from "components/shared/BexApiWrapper/ProductBexApiWrapper";
import { LazyLoad } from "src/components/utility/LazyLoad/LazyLoad";
import { IntersectionTracker } from "@shared-ui/clickstream-analytics-context";

const WrappedAnweringTravellerQuestion = ProductBexApiWrapper(AnsweringTravellerQuestionsContentSearch);

export const AnsweringTravellerQuestions = ({ context }: { context: ExtendedContextStore }) => (
  // LazyLoad to be removed when the experiment is over, will be added to `view` attribute in template region
  <IntersectionTracker
    referrerId="HOT.HIS.AnsweringTravellerQuestions"
    trackOnce
    linkName="Answering Traveller Questions"
  >
    <Experiment name="Traveller_QnA_Choose_a_room_CTA_in_modal">
      <ExperimentControl />
      <ExperimentVariant bucket={1}>
        <LazyLoad context={context}>
          <UitkSpacing margin={{ block: "three" }}>
            <div data-testid="answering-traveller-question">
              <WrappedAnweringTravellerQuestion context={context} skipSsr />
            </div>
          </UitkSpacing>
        </LazyLoad>
      </ExperimentVariant>
    </Experiment>
  </IntersectionTracker>
);

export default AnsweringTravellerQuestions;
